const transitions = {
  normal: '0.5s',
};

const fontSize = {
  small: '0.9rem',
  big: '2.9rem',
};

export { transitions, fontSize };
